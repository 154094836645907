import Client from '../../lib/fetch/Client'
import * as Utils from '../../url'

export default class WeatherClient {
    constructor(base) {
        const url = Utils.buildPath(base, 'stations')

        this.client = new Client(url)
    }
    async stations() {
        const stations = await this.client.get()

        return stations.sort(sorter)
    }
    station(id) {
        return this.client.get(id)
    }
    measurements(stationId) {
        return this.client.get([stationId, 'measurements'])
    }
}

// Utils
// TODO Move that logic to the server!
function sorter(a, b) {
    return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
}
